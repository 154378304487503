.authBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    z-index: -2;
}

.authBgDark {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.authCard {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}
